import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  OnChanges,
} from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';

import { Router, NavigationEnd } from '@angular/router';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../core/services/event.service';
import { AccessService } from 'src/app/core/services/access.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() isCondensed = false;
  @Output() mobileMenuButtonClicked = new EventEmitter();
  flightBookingCount: number = 0;
  currentUrl: string;
  //sideMenuResponse: any = [{ header: 'flight' }, { header: 'visa' }, {header:'Settings'}]
  sideMenuResponse: any;
  menu: any;
  selectedModule: string;
  menuItems: MenuItem[] = [];

  moduleColors = {
    Flight: '#00BFFF',
    Hotel: '#228B22',
    Holiday: '#DAA520',
    Visa: '#b96943',
    Settings: '#708090',
    Customer: '#b96943',
    Taj: '#708090',
  };

  @ViewChild('sideMenu') sideMenu: ElementRef;
  @ViewChild('componentRef') scrollRef;

  constructor(
    private eventService: EventService,
    private router: Router,
    public translate: TranslateService,
    private AccessService: AccessService,
    private authService: AuthenticationService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url
        this._activateMenuDropdown();
        this._scrollElement();
        if (event.url === '/flight/bookings') {
          this.authService.setFlightBookingCount(0)
        }
      }
    });
    
    this._activateMenuDropdown();
    this.AccessService.selectedModule$.subscribe((module) => {
      this.selectedModule = module;
      this.filterMenuItems(this.selectedModule);
    });
  }

  ngOnInit() {

    this.initialize();
    this._scrollElement();
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.classList.remove('authentication-bg');

    this.filterMenuItems(this.selectedModule);
    this.checkFlightCount();
  }
  setModuleTobeSelected() {
    if (this.router.url.includes('flight')) {
      this.selectedModule = 'Flight';
      this.AccessService.setSelectedModule('Flight');
    } else if (this.router.url.includes('hotel')) {
      this.selectedModule = 'Hotel';
      this.AccessService.setSelectedModule('Hotel');
    } else if (this.router.url.includes('holiday')) {
      this.selectedModule = 'Holiday';
      this.AccessService.setSelectedModule('Holiday');
    } else if (this.router.url.includes('settings')) {
      this.selectedModule = 'Settings';
      this.AccessService.setSelectedModule('Settings');
    } else if (this.router.url.includes('customer')) {
      this.selectedModule = 'Customer';
      this.AccessService.setSelectedModule('Customer');
    } else if (this.router.url.includes('visa')) {
      this.selectedModule = 'Visa';
      this.AccessService.setSelectedModule('Visa');
    }else if (this.router.url.includes('taj')) {
      this.selectedModule = 'Taj';
      this.AccessService.setSelectedModule('Taj');
    }
  }
  checkFlightCount() {
    this.authService.getFlightBookingCount().subscribe((res) => {
      this.flightBookingCount = res;
    });
  }

  filterMenuItems(selectedModule: string): void {
    // Implement logic to filter menu items based on the selected module
   
    let filteredItems: MenuItem[] = [];
    let sideBarMenu = [];
    let sideBarMenu1 = [];
    const filteredData: MenuItem[] = [];
    this.sideMenuResponse = JSON.parse(localStorage.getItem('permision'));
    if (selectedModule != '') {
      this.sideMenuResponse.forEach(item => {
        if (item?.header.toLowerCase() === selectedModule?.toLowerCase()) { sideBarMenu1.push({ ...item }) }

      });
      sideBarMenu1.forEach(item => {
        if (item?.['sub-header']) {
          item['sub-header'] = item['sub-header'].filter(subItem => subItem.permission.view == true);
        }
      });
      sideBarMenu1.forEach(subItem => {
        subItem['sub-header'].forEach(item=>{
          if (item?.['sub-menu']) {
            item['sub-menu'] = item['sub-menu'].filter(subItem => subItem.permission.view == true);
          }
        })
      });
    }
   
    let module: any = [];
    MENU.forEach(item => {
      if (item.Module === selectedModule) {
        module.push({ ...item })
      }
    });
    module[0]?.Menu.forEach((menuModule) => {
      sideBarMenu1.forEach(item => {

        item['sub-header'].forEach(subHead => {
          if (subHead.name.toLowerCase() == menuModule.label.toLowerCase()) {
            if (menuModule?.link) {
              filteredData.push({ ...menuModule });
            } else {
              let data=[];
              subHead['sub-menu'].forEach(item => {
                 menuModule?.subItems?.forEach(subItem =>{ 
                  if (subItem.label.toLowerCase() == item.name.toLowerCase())
                    data.push(subItem)
                  });
              });
             
              filteredData.push({ ...menuModule, subItems : data });
              this._activateMenuDropdown();
            }
          }
        })
      });
    })

    this.menuItems = filteredData;
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url
        this._activateMenuDropdown();
      }
    });
  }

  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout: string) {
    this.eventService.broadcast('changeLayout', layout);
  }

  ngAfterViewInit() {
    
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this.AccessService.urlChanges$.subscribe(item=>{
      this._activateMenuDropdown(item);
    })
    this._activateMenuDropdown();
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Light sidebar
   */
  darkSidebar() {
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-sidebar-size');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.classList.remove('vertical-collpsed');
  }

  /**
   * Compact sidebar
   */
  compactSidebar() {
    document.body.setAttribute('data-sidebar-size', 'small');
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-topbar');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.classList.remove('sidebar-enable');
    document.body.classList.remove('vertical-collpsed');
  }

  /**
   * Icon sidebar
   */
  iconSidebar() {
    document.body.classList.add('sidebar-enable');
    document.body.classList.add('vertical-collpsed');
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.removeAttribute('data-topbar');
  }

  /**
   * Boxed layout
   */
  boxedLayout() {
    document.body.setAttribute('data-keep-enlarged', 'true');
    document.body.setAttribute('data-layout-size', 'boxed');
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.classList.add('vertical-collpsed');
    document.body.classList.remove('sidebar-enable');
    document.body.removeAttribute('data-topbar');
  }

  /**
   * Colored sidebar
   */
  coloredSidebar() {
    document.body.setAttribute('data-sidebar', 'colored');
    document.body.removeAttribute('data-sidebar-size');
    document.body.removeAttribute('data-layout-size');
    document.body.classList.remove('vertical-collpsed');
    document.body.removeAttribute('data-topbar');
  }

  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName('mm-active').length > 0) {
        const currentPosition =
          document.getElementsByClassName('mm-active')[0]['offsetTop'];
        if (currentPosition > 500)
          if (this.scrollRef.SimpleBar) {
            if (this.scrollRef.SimpleBar.getScrollElement() !== null) {
              this.scrollRef.SimpleBar.getScrollElement().scrollTop =
                currentPosition + 300;
            }
          }
      }
    }, 300);
  }
  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown(data?) {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    let links = document.getElementsByClassName('side-nav-link-ref');
    
    if(data){
       links = data;
    }
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('mm-active');
        const parent2El = parentEl.parentElement.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');
            if (childAnchor) {
              childAnchor.classList.add('mm-active');
            }
            if (childDropdown) {
              childDropdown.classList.add('mm-active');
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
                const childanchor = parent5El.querySelector('.is-parent');
                if (childanchor && parent5El.id !== 'side-menu') {
                  childanchor.classList.add('mm-active');
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = MENU;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
}
