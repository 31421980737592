import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserProfileService } from '../services/user.service';
import { ContactsService } from '../services/contacts.service';
import { MENU } from 'src/app/layouts/sidebar/menu';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  // sideMenuResponse: any = [{ header: 'flight', permission: true }, { header: 'visa', permission: false }]
  sideMenuResponse: any = [];
  constructor(
    private router: Router,
    private authService: UserProfileService,


  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.sideMenuResponse = JSON.parse(localStorage.getItem('permision'));
    // not logged in so redirect to login page with the return url
    let url = [];
    MENU.forEach((module) => {
      
      this.sideMenuResponse?.forEach(item => {
        if (item?.header.toLowerCase() === module.Module.toLowerCase() && item.permission) {
          module.Menu.forEach(menuModule => {
            if (menuModule?.link) url.push({ url: menuModule?.link, module: module.Module, menu: menuModule.label, submenu: null })
            else menuModule.subItems.forEach(subItem => {
              url.push({ url: subItem?.link, module: item.header, menu: menuModule.label, submenu: subItem.label })
            })
          })
        }
      })
    })

    if (this.authService.isAuthenticatedUser()) {
      let canActivateRoute : boolean = false;
      url.forEach((module) => {
        if (module.url === state.url) {
          this.sideMenuResponse.forEach(item => {
            if (item.header.toLowerCase() == module.module.toLowerCase()) {
              item['sub-header'].forEach(menu => {
                if (module.submenu && module.menu.toLowerCase() == menu.name.toLowerCase()) {
                  menu['sub-menu'].forEach(subItem => {
                    if (module.submenu.toLowerCase() == subItem.name.toLowerCase()) {
                      canActivateRoute = subItem.permission.view;
                    }
                  });
                } else {
                  if (module.menu.toLowerCase() == menu.name.toLowerCase()) {
                    canActivateRoute = menu.permission.view;
                  }
                }
              })
            }
          })

          // If the module matches the selected module, include its items

        }
      });
      if (canActivateRoute) {
        return true; // Allow navigation
      } else {
        // If the user does not have permission, redirect to login
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return false; // Prevent navigation
      }
    }
    // })
    // Allow access to the route if the user is authenticated.
    else {
      this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
      return false; // Prevent access to the route.
    }
    return false;
  }
}
