import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccessService {
  constructor(private http: HttpClient) { }

  private offerSubject = new BehaviorSubject('');
  offerSubject$ = this.offerSubject.asObservable();

  private ModalSubject = new Subject<boolean>();
  ModalSubject$ = this.ModalSubject.asObservable();

  setValueTofferSubject(data: any) {
    this.offerSubject.next(data);
  }

  setModalValue(value: boolean) {
    this.ModalSubject.next(value);
  }

  private countrySubject = new BehaviorSubject(null);
  countrySubject$ = this.countrySubject.asObservable();

  setCountry(value: string) {
    this.countrySubject.next(value);
  }

  private countryOfferSubject = new BehaviorSubject(null);
  countryOfferSubject$ = this.countryOfferSubject.asObservable();

  setCountryOffer(value: string) {
    this.countryOfferSubject.next(value);
  }

  private citySubject = new BehaviorSubject(null);
  citySubject$ = this.citySubject.asObservable();

  setCity(value: string) {
    this.citySubject.next(value);
  }

  private cityListSubject = new BehaviorSubject([]);
  cityListSubject$ = this.cityListSubject.asObservable();

  setCityList(value: any) {
    this.cityListSubject.next(value);
  }
  private urlChanges = new BehaviorSubject([])
  urlChanges$ = this.urlChanges.asObservable();

  setUrlChanges(value: any) {
    this.urlChanges.next(value)
  }


  private selectedModuleSubject = new BehaviorSubject<string>('');
  selectedModule$: Observable<string> =
    this.selectedModuleSubject.asObservable();

  setSelectedModule(module: string): void {
    this.selectedModuleSubject.next(module);
  }

  get_gallery(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/gallery/`);
  }

  save_gallery(user) {
    return this.http.post(`${environment.apiUrl}/gallery/`, user);
  }
  uploadFile(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/gallery/`, data, {
      reportProgress: true,
      observe: 'events',
    });
  }

  get_roles(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/role/`);
  }

  get_country(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/country/`);
  }
  get_fromTocountryList(is_from, search_key?): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiUrl}/visa/country/list?is_from=${is_from}&search_key=${search_key}`
    );
  }

  get_airline(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/airline/`);
  }

  get_popular(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/popular_destination/`);
  }

  get_code(search: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiUrl}/airport_code/?search=${search}`
    );
  }

  get_offer_list(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/offer_type/`);
  }

  save_offer_single(user) {
    return this.http.post(`${environment.apiUrl}/offer/`, user);
  }

  get_offer_list_all(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/offer/`);
  }

  save_offerrr(user, data: any, options?: any) {
    return this.http.post(`${environment.apiUrl}/offer/`, user);
  }

  save_offer(user) {
    return this.http.post(`${environment.apiUrl}/offer/`, user);
  }

  save_offer_edit(user) {
    const id = user.id;
    const uuid = user.uuid;
    const data = { value: user.value };
    return this.http.patch(
      `${environment.apiUrl}/offer/?id=${id}&uuid=${uuid}`,
      user
    );
  }

  save_i_edit(user) {
    const id = user.id;
    const uuid = user.uuid;
    const data = { value: user.value };
    return this.http.patch(
      `${environment.apiUrl}/flight_deals/?id=${id}&uuid=${uuid}`,
      user
    );
  }

  save_d_edit(user) {
    const id = user.id;
    const uuid = user.uuid;
    const data = { value: user.value };
    return this.http.patch(
      `${environment.apiUrl}/flight_deals/?id=${id}&uuid=${uuid}`,
      user
    );
  }

  save_p_edit(user) {
    const id = user.id;
    const uuid = user.uuid;
    const data = { value: user.value };
    return this.http.patch(
      `${environment.apiUrl}/popular_destination/?id=${id}&uuid=${uuid}`,
      user
    );
  }

  save_home_edit(user) {
    const id = user.id;
    const uuid = user.uuid;
    const data = { value: user.value };
    return this.http.patch(
      `${environment.apiUrl}/home_description/?id=${id}&uuid=${uuid}`,
      user
    );
  }

  // save_a_edit(user) {
  //   const id = user.id;
  //   const uuid = user.uuid;
  //   const data={value:user.value}
  //   return this.http.patch(`${environment.apiUrl}/flight_deals/?id=${id}&uuid=${uuid}`, user);
  // }

  // save_about_cms(user: any) {
  //   const id = user.id;
  //   const data={value:user.value}
  //   return this.http.patch(`${environment.apiUrl}/city_detail/?id=${id}`,data);
  // }

  add_role(user) {
    return this.http.post(`${environment.apiUrl}/role/`, user);
  }

  save_interNat(user) {
    return this.http.post(`${environment.apiUrl}/flight_deals/`, user);
  }

  save_about_temp(user) {
    return this.http.patch(`${environment.apiUrl}/template/`, user);
  }

  save_about_cms(user: any) {
    const id = user.id;
    const data = { prompt: user.prompt };
    return this.http.patch(`${environment.apiUrl}/city_detail/?id=${id}`, data);
  }

  generate_about_cms(user: any) {
    const id = user.id;
    const city_id = user.city_id;
    const data = {
      prompt: user.prompt,
      prompt_id: user.id,
      city_id: user.city_id,
    };
    return this.http.post(`${environment.apiUrl}/generate/`, data);
  }

  saveGen_about_cms(user: any) {
    const id = user.id;
    const city = user.city;
    const data = { value: user.prompt, city_id: user.city, prompt_id: user.id };
    return this.http.patch(`${environment.apiUrl}/generate/`, data);
  }

  save_cms_instruction(user: any) {
    const id = user.id;
    const data = { value: user.prompt };
    return this.http.patch(`${environment.apiUrl}/city_detail/?id=${id}`, data);
  }

  save_cms_activity(user: any) {
    const id = user.id;
    const data = { value: user.value };
    return this.http.patch(`${environment.apiUrl}/city_detail/?id=${id}`, data);
  }

  save_about_activity(user) {
    return this.http.patch(`${environment.apiUrl}/template/`, user);
  }

  save_about_instruction(user) {
    return this.http.patch(`${environment.apiUrl}/template/`, user);
  }

  get_template_about(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiUrl}/template/?category=About`
    );
  }

  get_template_activity(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiUrl}/template/?category=Activities`
    );
  }

  get_template_instruction(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiUrl}/template/?category=Instructions`
    );
  }

  save_domestic(user) {
    return this.http.post(`${environment.apiUrl}/flight_deals/`, user);
  }

  save_description(user) {
    return this.http.post(`${environment.apiUrl}/home_description/`, user);
  }

  save_pop(user) {
    return this.http.post(`${environment.apiUrl}/popular_destination/`, user);
  }
  save_popular(user) {
    return this.http.post(`${environment.apiUrl}/popular_destination/`, user);
  }

  save_airline(user) {
    return this.http.post(`${environment.apiUrl}/airline/`, user);
  }

  add_user(user) {
    return this.http.post(`${environment.apiUrl}/user/`, user);
  }

  // add_OFFER(user) {
  //   return this.http.post(`${environment.apiUrl}/user/`, user);
  // }

  add_cities(user) {
    return this.http.post(`${environment.apiUrl}/city/`, user);
  }

  edit_user(user) {
    return this.http.patch(`${environment.apiUrl}/user/`, user);
  }

  list_country(contrId: any, typeId: any) {
    // Assuming 'id' is a property of the 'user' object
    return this.http.get<any[]>(
      `${environment.apiUrl}/offer/?country_id=${contrId}&category=Flight`
    );
  }

  list_country_air(contrId: any) {
    // Assuming 'id' is a property of the 'user' object
    return this.http.get<any[]>(
      `${environment.apiUrl}/airline/?country_id=${contrId}`
    );
  }

  list_country_inter(contrId: any) {
    // Assuming 'id' is a property of the 'user' object
    return this.http.get<any[]>(
      `${environment.apiUrl}/flight_deals/?country_id=${contrId}&is_international=True`
    );
  }

  list_country_dom(contrId: any) {
    // Assuming 'id' is a property of the 'user' object
    return this.http.get<any[]>(
      `${environment.apiUrl}/flight_deals/?country_id=${contrId}&is_international=False`
    );
  }

  // list_country_description(contrId: any) {
  //   // Assuming 'id' is a property of the 'user' object
  //   return this.http.get<any[]>(`${environment.apiUrl}/flight_deals/?country_id=${contrId}&is_international=False`);
  // }

  list_country_pop(contrId: any) {
    // Assuming 'id' is a property of the 'user' object
    return this.http.get<any[]>(
      `${environment.apiUrl}/popular_destination/?country_id=${contrId}&is_international=True`
    );
  }

  get_user_List(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/user/`);
  }

  get_country_List(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/country/`);
  }

  get__taj_country_List(contrId: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/taj/country/?country_id=${contrId}`);
  }

  get_city_List(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/city/`);
  }

  get_generated_List(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/generate/`);
  }

  filtered_city_list(cityId: any) {
    // return this.http.get<any[]>(`${environment.apiUrl}/city_detail/?city_id=${cityId}`);
    return this.http.get<any[]>(
      `${environment.apiUrl}/city_detail/?city_id=${cityId}&category=About`
    );
  }

  filtered_city_activity(cityId: any) {
    // return this.http.get<any[]>(`${environment.apiUrl}/city_detail/?city_id=${cityId}`);
    return this.http.get<any[]>(
      `${environment.apiUrl}/city_detail/?city_id=${cityId}&category=Activities`
    );
  }

  filtered_city_instruction(cityId: any) {
    // return this.http.get<any[]>(`${environment.apiUrl}/city_detail/?city_id=${cityId}`);
    return this.http.get<any[]>(
      `${environment.apiUrl}/city_detail/?city_id=${cityId}&category=Instructions`
    );
  }

  // get_action():Observable<any[]>{
  //   return this.http.get<any[]>(`${environment.apiUrl}/deactivate_user/`);
  // }

  get_action(user: any) {
    const id = user.id; // Assuming 'id' is a property of the 'user' object
    return this.http.post(`${environment.apiUrl}/deactivate_user/${id}/`, user);
  }

  get_action_city(user: any) {
    const id = user.id; // Assuming 'id' is a property of the 'user' object
    return this.http.post(`${environment.apiUrl}/deactivate_city/${id}/`, user);
  }

  // organisation(user) {
  // const body = { organization_name};
  //     return this.http.post(`${environment.apiUrl}/organization/`, user);
  // }

  update_role(user: any) {
    return this.http.patch(`${environment.apiUrl}/role/`, user);
  }

  isAuthenticatedUser(): boolean {
    return localStorage.getItem('ACCESS_TOKEN') ? true : false;
  }

  // list_country_air(contrId: any) {
  //   // Assuming 'id' is a property of the 'user' object
  //   return this.http.get<any[]>(`${environment.apiUrl}/airline/?country=${contrId}`);
  // }

  save_air_edit(user) {
    const id = user.id;
    const uuid = user.uuid;
    const data = { value: user.value };
    return this.http.patch(
      `${environment.apiUrl}/airline/?id=${id}&uuid=${uuid}`,
      user
    );
  }

  save_airlineP(user) {
    return this.http.post(`${environment.apiUrl}/airline/`, user);
  }

  list_country_dscription(contrId: any) {
    // Assuming 'id' is a property of the 'user' object
    return this.http.get<any[]>(
      `${environment.apiUrl}/home_description/?country=${contrId}`
    );
  }

  get_integration_List(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/integration/`);
  }

  integration_save(formData) {
    return this.http.post(`${environment.apiUrl}/integration/`, formData);
  }

  integration_Edit(formData) {
    return this.http.patch(`${environment.apiUrl}/integration/`, formData);
  }

  integration_delete(user) {
    const id = user.id;
    return this.http.delete(
      `${environment.apiUrl}/integration/?id=${id}`,
      user
    );
  }

  OfferCoupons(CountryId: any): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiUrl}/offercoupon/?country_id=${CountryId}`
    );
  }

  // filtered_city_list(cityId: any) {
  //   // return this.http.get<any[]>(`${environment.apiUrl}/city_detail/?city_id=${cityId}`);
  //   return this.http.get<any[]>(`${environment.apiUrl}/city_detail/?city_id=${cityId}&category=About`);
  // }

  // OfferCoupons(country: any): Observable<any[]> {
  //   let params = new HttpParams().set('country_id', country);
  //   // if (type !== undefined && type !== null) {
  //   //   params = params.set('city_name', type);
  //   // }
  //   return this.http.get<any[]>(`${environment.apiUrl}/offercoupon/`,{ params });
  // }

  save_Offer_coupon(user) {
    return this.http.post(`${environment.apiUrl}/offercoupon/`, user);
  }

  active_coupon(data: any) {
    // const id = user.id; // Assuming 'id' is a property of the 'user' object
    return this.http.post(`${environment.apiUrl}/offer_active_visible/`, data);
  }

  visible_coupon(data: any) {
    // const id = user.id;
    // Assuming 'id' is a property of the 'user' object
    return this.http.post(`${environment.apiUrl}/offer_active_visible/`, data);
  }

  UniqueCode(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/offercode/`);
  }

  get_country_action(user: any) {
    const id = user.id; // Assuming 'id' is a property of the 'user' object
    return this.http.post(`${environment.apiUrl}/deactivate_country/`, user);
  }

  get_bookings(page, filterData: any): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiUrl}/all-bookings/?page=${page}${filterData?.from_date ? `&from_date=${filterData?.from_date}` : ''
      }${filterData?.to_date ? `&to_date=${filterData?.to_date}` : ''}${filterData?.source ? `&source=${filterData?.source}` : ''
      }${filterData?.search ? `&search=${filterData?.search}` : ''}`
    );
  }

  get_bookings_count(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/booking/count`);
  }

  get_user_bookings(bookingId: any): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiUrl}/booking-action/${bookingId}`
    );
  }

  get_user_bookings_reference(Id: any): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiUrl}/booking-reference-details?booking_reference_id=${Id}`
    );
  }

  country_details(user: any) {
    // const id = user.id; // Assuming 'id' is a property of the 'user' object
    return this.http.patch(`${environment.apiUrl}/country/`, user);
  }

  country_details_taj(user: any) {
    // const id = user.id; // Assuming 'id' is a property of the 'user' object
    return this.http.patch(`${environment.apiUrl}/taj/country-update/`, user);
  }


  // get_refresh(Id:any): Observable<any[]> {
  //   return this.http.get<any[]>(`${environment.apiUrl}/refresh-booking?booking_reference_id=${Id}`);
  // }

  get_refresh(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      `${environment.apiUrl}/refresh-booking/`,
      data
    );
  }

  popular_delete(user) {
    const id = user.id;
    return this.http.delete(
      `${environment.apiUrl}/popular_destination/?id=${id}`,
      user
    );
  }

  offer_delete(user) {
    const id = user.id;
    return this.http.delete(`${environment.apiUrl}/offer/?id=${id}`, user);
  }

  international_delete(user) {
    const id = user.id;
    return this.http.delete(
      `${environment.apiUrl}/flight_deals/?id=${id}`,
      user
    );
  }

  domestic_delete(user) {
    const id = user.id;
    return this.http.delete(
      `${environment.apiUrl}/flight_deals/?id=${id}`,
      user
    );
  }

  description_delete(user) {
    const id = user.id;
    return this.http.delete(
      `${environment.apiUrl}/home_description/?id=${id}`,
      user
    );
  }

  airline_delete(user) {
    const id = user.id;
    return this.http.delete(`${environment.apiUrl}/airline/?id=${id}`, user);
  }

  get_passenger(booking_id: number[]): Observable<any[]> {
    const params = { booking_id: booking_id.join(',') }; // Convert array of ids to a comma-separated string
    return this.http.get<any[]>(`${environment.apiUrl}/booking/change-status`, {
      params,
    });
  }

  edit_passenger(user: any, booking_id: number[]) {
    const params = { booking_id: booking_id.join(',') };
    return this.http.patch(
      `${environment.apiUrl}/booking/change-status`,
      user,
      { params }
    );
  }

  banner_save(user) {
    return this.http.post(`${environment.apiUrl}/holiday/login/banner`, user);
  }
  banner_list(contrId: any) {
    return this.http.get<any[]>(
      `${environment.apiUrl}/holiday/login/banner?country_id=${contrId}`
    );
  }

  banner_list_edit(user) {
    // const id = user.id;
    return this.http.patch(`${environment.apiUrl}/holiday/login/banner`, user);
  }

  banner_list_delete(user) {
    const id = user.id;
    return this.http.delete(
      `${environment.apiUrl}/holiday/login/banner?id=${id}`,
      user
    );
  }

  holiday_offer_single_country(user) {
    return this.http.post(`${environment.apiUrl}/holiday/offer`, user);
  }

  holiday_offer_all_country(user) {
    return this.http.post(`${environment.apiUrl}/holiday/offer`, user);
  }

  //footer domestic
  save_footer_dom(user) {
    return this.http.post(`${environment.apiUrl}/api/flights/create/`, user);
  }

  list_footer_dom(contrId: any) {
    return this.http.get<any[]>(
      `${environment.apiUrl}/api/flights/domestic/?country_id=${contrId}&is_domestic=True`
    );
  }

  footer_dom_edit(user) {
    const id = user.id;
    return this.http.put(
      `${environment.apiUrl}/api/flights/update/${id}/`,
      user
    );
  }

  footer_dom_delete(user) {
    const id = user.id;
    return this.http.delete(
      `${environment.apiUrl}/api/flights/delete/${id}/`,
      user
    );
  }

  //footer international
  save_footer_int(user) {
    return this.http.post(`${environment.apiUrl}/api/flights/create/`, user);
  }

  list_footer_int(contrId: any) {
    return this.http.get<any[]>(
      `${environment.apiUrl}/api/flights/domestic/?country_id=${contrId}&is_domestic=False`
    );
  }

  footer_int_edit(user) {
    const id = user.id;
    return this.http.put(
      `${environment.apiUrl}/api/flights/update/${id}/`,
      user
    );
  }

  footer_int_delete(user) {
    const id = user.id;
    return this.http.delete(
      `${environment.apiUrl}/api/flights/delete/${id}/`,
      user
    );
  }

  //footer social media
  media_list(contrId: any) {
    return this.http.get<any[]>(
      `${environment.apiUrl}/social-links?country=${contrId}`
    );
  }

  media_list_edit(user) {
    // const id = user.id;
    return this.http.put(`${environment.apiUrl}/social-links`, user);
  }

  // banner_list_edit(user) {
  //   // const id = user.id;
  //   return this.http.patch(`${environment.apiUrl}/holiday/login/banner`, user);
  // }
  // offline section
  get_offline_bookings(page): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiUrl}/offline-customers?page=${page}`
    );
  }
  save_offlineCustomers(data) {
    return this.http.post(`${environment.apiUrl}/offline-customers`, data);
  }
  // ppc
  addSlugPpc(data: any) {
    return this.http.post(`${environment.apiUrl}/flight-ppc/`, data);
  }
  getSlugPpc() {
    return this.http.get<any[]>(`${environment.apiUrl}/flight-ppc`);
  }
  editSlugPpc(data: any, id: any) {
    return this.http.put(`${environment.apiUrl}/flight-ppc/${id}/`, data);
  }
  checkExistSlug(data: any) {
    return this.http.post(
      `${environment.apiUrl}/flight-ppc/slug-validation/`,
      data
    );
  }
  getSlugHotelPpc() {
    return this.http.get<any[]>(`${environment.apiUrl}/hotel/hotel-ppc/`);
  }
  // hotel ppc
  checkExistSlugHotel(data: any) {
    return this.http.post(
      `${environment.apiUrl}/hotel/hotel-ppc/slug-validation/`,
      data
    );
  }
  addSlugPpcHotel(data: any) {
    return this.http.post(`${environment.apiUrl}/hotel/hotel-ppc/`, data);
  }
  editSlugPpcHotel(data: any, id: any) {
    return this.http.put(`${environment.apiUrl}/hotel/hotel-ppc/${id}/`, data);
  }

  //add role=======================================================================================
  createRole(data: any) {
    return this.http.post(`${environment.apiUrl}/create-Group/`, data);
  }
  editRole(data: any) {
    return this.http.patch(`${environment.apiUrl}/create-Group/`, data);
  }
  getRole(data: any) {
    const params = { group_id: data.group_id };
    return this.http.get(`${environment.apiUrl}/create-Group/`, { params });
  }
  getAllRoles() {
    return this.http.get(`${environment.apiUrl}/all-roles/`);
  }
  getAddedRoles() {
    return this.http.get(`${environment.apiUrl}/all-groups/`);
  }
  addUser(data: any) {
    return this.http.post(`${environment.apiUrl}/user/`, data);
  }
  editUser(data: any) {
    return this.http.patch(`${environment.apiUrl}/user/`, data);
  }
  userActivateOrDectivate(id: any) {
    const params = { user_id: id };
    return this.http.get(`${environment.apiUrl}/change-status-user/`, { params });
  }
  //changing password api====================================================
  edit_user_password(requestBody: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/edit/user/password`, requestBody);
  }

}
