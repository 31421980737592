import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';

import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/core/models/auth.models';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AccessService } from 'src/app/core/services/access.service';
import { MENU } from '../sidebar/menu';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  @ViewChild('SidebarComponent') SidebarComponent!: ElementRef;
  element;
  configData;
  cookieValue;
  flagvalue: any;
  countryName: any;
  valueset;
  //sideMenuResponse: any = [{ header: 'flight' }, { header: 'visa' },{header:'Settings'}]
  sideMenuResponse: any;
  pList: any[] = [];
  selectedModule: string = '';
  moduleList = [{ Module: 'Customer', image_icon: 'fa fa-user customer' },
  { Module: 'Flight', image_icon: 'fa fa-plane flight' },
  { Module: 'Hotel', image_icon: 'fa fa-bed hotel' },
  { Module: 'Holiday', image_icon: 'fa fa-suitcase holiday' },
  { Module: 'Visa', image_icon: 'fa fa-rocket visa' },
  { Module: 'Taj', image_icon: 'fa fa-map-marker taj' },

  { Module: 'Settings', image_icon: 'fa fa-cog settings' }]
  permittedList = [];
  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private AccessService: AccessService
  ) { }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.setModuleTobeSelected();
    this.permittedModule();
    // this.set_permission()

    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3,
    };

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }
  permittedModule() {
    this.sideMenuResponse = JSON.parse(localStorage.getItem('permision'));
    this.sideMenuResponse.forEach(item => {
      
      this.moduleList.forEach(permission => {
        if (permission.Module.toLocaleLowerCase() == item.header.toLocaleLowerCase() && item.permission) {
          this.permittedList.push(permission);
          // console.log('this.permittedList.push(permission)',this.permittedList.push(permission))
          
        }

        console.log('permittedList',this.permittedList?.length)

      })
    })
  }

  setModuleTobeSelected() {
    if (this.router.url.includes('flight')) {
      this.selectedModule = 'Flight';
      this.AccessService.setSelectedModule('Flight');
    } else if (this.router.url.includes('hotel')) {
      this.selectedModule = 'Hotel';
      this.AccessService.setSelectedModule('Hotel');
    } else if (this.router.url.includes('holiday')) {
      this.selectedModule = 'Holiday';
      this.AccessService.setSelectedModule('Holiday');
    } else if (this.router.url.includes('settings')) {
      this.selectedModule = 'Settings';
      this.AccessService.setSelectedModule('Settings');
    } else if (this.router.url.includes('customer')) {
      this.selectedModule = 'Customer';
      this.AccessService.setSelectedModule('Customer');
    } else if (this.router.url.includes('visa')) {
      this.selectedModule = 'Visa';
      this.AccessService.setSelectedModule('Visa');
    }else if (this.router.url.includes('taj')) {
      this.selectedModule = 'Taj';
      this.AccessService.setSelectedModule('Taj');
    }
  }

  /**
   * Language set
   * @param text
   * @param lang
   * @param flag
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    // alert("test");
    //user logout
    localStorage.removeItem('currentUser');
    localStorage.removeItem('ACCESS_TOKEN');
    // this.currentUserSubject.next(null);
    this.router.navigate(['/account/login']);
  }

  profile_view() {
    this.router.navigate(['/account/profile']);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  // set_permission(){

  //   this.AccessService.get_p_List().subscribe(data => {
  //     this.pList = data;
  //     // this.isLoading = false;
  //   });

  // }

  selectModule(module: string): void {
    
    console.log('module',module)

    this.AccessService.setSelectedModule(module);
    let sideBar = JSON.parse(localStorage.getItem('permision'));
    let accessUrl;
    
    sideBar.forEach((item: any) => {
      if (item.permission && !accessUrl && item.header.toLowerCase() == module.toLowerCase()) { accessUrl = item; return }
    });
    if (accessUrl?.['sub-header']) accessUrl['sub-header'] = accessUrl?.['sub-header'].filter(subItem => subItem.permission.view == true);
    if (accessUrl?.['sub-header']) accessUrl['sub-header'].forEach(item => {
      if (item?.['sub-menu']) {
        item['sub-menu'] = item['sub-menu'].filter(subItem => subItem.permission.view == true);
      }
    })
    
    this.selectedModule = module;
    let url: any;
    MENU.forEach(module => {
      if (module.Module.toLowerCase() === accessUrl?.header.toLowerCase()) {
        accessUrl['sub-header'].forEach((subItem: any) => {
          if (subItem.permission.view) {
            module.Menu.forEach((menuModule: any, index: number) => {
              if (subItem.permission.view && menuModule.label.toLowerCase() == subItem.name.toLowerCase()) {
                if (index > 0) {
                  if ((menuModule.priority < module.Menu[index - 1]['priority']) || !url && subItem.permission.view) {
                    if (menuModule?.link && menuModule.label.toLowerCase() == subItem.name.toLowerCase()) {
                      url = menuModule?.link;
                    } else if (!menuModule?.link) {
                      menuModule?.subItems?.forEach((subItems: any, index: number) => {
                        subItem['sub-menu']?.forEach(subMenu => {
                          if (subItems.priority >= menuModule['subItems'][index]['priority'] && subItem.permission.view) {
                            if (subItems.label.toLowerCase() == subMenu.name.toLowerCase()) {
                              url = subItems?.link;
                            }
                          }
                        })
                      })
                    }
                  }
                } else {
                  if (menuModule?.link) {
                    url = menuModule?.link;
                  } else {
                    menuModule?.subItems?.forEach((subItems: any, index: number) => {
                      subItem['sub-menu']?.forEach(subMenu => {
                        if (subItems.label.toLowerCase() == subMenu.name.toLowerCase() && subMenu.permission.view) {
                          if (index > 0) {
                            if (subItems.priority < menuModule.subItems[index - 1]['priority'] && subMenu.permission.view) {
                              url = subItems?.link;
                            }
                          } else {
                            url = subItems?.link;
                          }
                        }
                      })
                    })
                  }
                }
              }
            })
          }
        })
      }
    })
    this.router.navigate([url]);
  }
}
