// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 // apiUrl: 'http://192.168.10.45:8000',
  // apiUrl: 'https://staging-api.bookotrip.in',
  hotelmailUrl:'https://web-api.bookotrip.com/web/hotel/',
  apiUrl:'https://api.bookotrip.com',
  // apiUrl:'http://192.168.10.76:8000',

  flagUrl:
    'https://stp-data-bucket.s3.ap-south-1.amazonaws.com/general/countries/',
  cityUrl: 'https://web-api.bookotrip.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
