import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        Module: 'Flight',
        Menu: [
            // {
            //     id: 1,
            //     label: 'MENUITEMS.MENU.TEXT',
            //     isTitle: true
            // },
            
            {
                id: 5,
                priority: 1,
                label: 'Bookings',
                icon: 'uil-invoice',
                link: '/flight/bookings',

            },

            {
                id: 4,
                priority: 2,
                label: 'CMS',
                icon: 'uil-store',
                subItems: [

                    {
                        id: 2,
                        priority: 1,
                        label: 'Home',
                        icon: 'uil-comments-alt',
                        link: '/flight/home/nav',
                    },

                    // {
                    //     id: 5,
                    //     label: 'City',
                    //     link: '/flight/cms-city/lists',
                    //     parentId: 4
                    // },
                    {
                        id: 6,
                        priority: 2,
                        label: 'PPC',
                        link: '/flight/cms-city/ppcslug',
                        parentId: 4
                    },

                    {
                        id: 6,
                        priority: 3,
                        label: 'PPC FAQ',
                        link: '/flight/cms-city/ppc',
                        parentId: 4
                    },

                ]
            },
            {
                id: 2,
                priority: 3,
                label: 'Offers',
                icon: 'uil-percentage',
                link: '/flight/offers/offer-home',

            },
            {
                id: 2,
                label: 'MENUITEMS.DASHBOARDS.TEXT',
                priority: 5,
                icon: 'uil-home-alt',
                link: '/flight/dashboard',
            },


        ]
    },
    {
        Module: 'Settings',
        Menu: [

            {
                id: 2,
                priority: 1,
                label: 'Country',              
                icon: 'uil-globe',
                link: '/settings/country-module/country',
            },

            {
                id: 2,
                priority: 2,
                label: 'Access management',
                icon: 'uil-lock-access',
                link: '/settings/role/nav',

            },

            // {
            //     id: 2,
            //     priority: 3,
            //     label: 'Template',
            //     icon: 'uil-document-layout-center',
            //     link: '/settings/role/template',
            // },

            {
                id: 2,
                priority: 3,
                label: 'Integration',
                icon: 'uil-arrows-merge',
                link: '/settings/role/integration',


            },

            {
                id:2,
                priority: 4,
                label:'Footer',
                icon:'uil-file-bookmark-alt',
                link:'/settings/role/footer'
            },
            {
                id:6,
                priority: 5,
                label:'Sitemap',
                icon:'uil-sitemap',
                link:'/settings/role/sitemap'
            },
            // {
            //     id:2,
            //     label:'Popular Airport',
            //     icon:'uil-plane-departure',
            //     link:'/settings/role/popular'
            // }
          
        ]
    },
    {
        Module: 'Holiday',
        Menu: [
            // {
            //     id: 4,
            //     label: 'CMS',
            //     icon: 'uil-cog',
            //     subItems: [

            //         {
            //             id: 5,
            //             label: 'Home',
            //             link: '/holiday/home',
            //             parentId: 4
            //         },
                   
            //     ]
            // },
            {
                id: 5,
                priority: 1,
                label: 'Enquiry',
                icon: 'uil-envelope-info',
                link: '/holiday/enquiry',

            },
            {
                id: 5,
                priority: 2,
                label: 'Products',
                icon: 'uil-folder',
                link: '/holiday/product',

            },
            {
                id: 4,
                priority: 3,
                label: 'CMS - Home',
                icon: 'uil-store',
                link: '/holiday/home',

            },

            // {
            //     id: 4,
            //     label: 'Settings',
            //     icon: 'uil-cog',
            //     subItems: [

            //         {
            //             id: 5,
            //             label: 'Theme',
            //             link: '/holiday/settings/theme',
            //             parentId: 4
            //         },
                   
            //     ]
            // },
            
            {
                id: 5,
                priority: 4,
                label: 'Theme - Settings',
                icon: 'uil-cog',
                link: '/holiday/settings/theme',

            },
            
            
        ]
    },
    // {
    //     Module: 'Customer',
    //     Menu: [

    //         {
    //             id: 1,
    //             priority: 1,
    //             label: 'All customers',              
    //             icon: 'uil-users-alt',
    //             link: '/customer/all-customers',
    //         },

            
    //     ]
    // },
    {
        Module:'Hotel',
        Menu:[
            {
                id: 4,
                label: 'CMS',
                icon: 'uil-cog',
                priority: 2,
                subItems: [

                    {
                        id: 5,
                        priority: 1,
                        label: 'Home',
                        link: '/hotel/home',
                        parentId: 4
                    },
                    {
                        id: 5,
                        priority: 2,
                        label: 'PPC',
                        link: '/hotel/hotelppc',
                        parentId: 4
                    },
                    {
                        id: 5,
                        label: 'PPC FAQ',
                        link: '/hotel/hotel-ppc-faq',
                        parentId: 4
                    },
                   
                ]
            },
            {
                id: 5,
                priority: 1,
                label: 'Bookings',
                icon: 'uil-invoice',
                link: '/hotel/bookings',

            },

        ]
    },
    {
        Module: 'Visa',
        Menu: [
           
            {
                id: 1,
                label: 'Enquiry',
                priority: 1,
                icon: 'uil-envelope-info',
                link: '/visa/enquiry',

            },
            {
                id: 2,
                priority: 2,
                label: 'Visa',
                icon: 'uil-folder',
                link: '/visa/visa',

            },
            {
                id: 3,
                priority: 3,
                label: 'CMS - Home',
                icon: 'uil-store',
                link: '/visa/CMS',

            },
            
            {
                id: 4,
                priority: 4,
                label: 'Settings',
                icon: 'uil-cog',
                subItems: [

                    {
                        id: 4,
                        priority: 1,
                        label: 'Category',
                        icon: 'uil-comments-alt',
                        link: '/visa/cms-content/visa-category',
                    },
                    {
                        id: 4,
                        priority: 2,
                        label: 'Type',
                        icon: 'uil-comments-alt',
                        link: '/visa/cms-content/visa-type',
                    },

                ]

            },
            
            
        ]
    }, 
    {
        Module:'Taj',
        Menu:[
            {
                id: 4,
                label: 'Holiday',
                icon: 'uil-suitcase',
                priority: 2,
                subItems: [

                    {
                        id: 5,
                        priority: 1,
                        label: 'CMS',
                        link: '/taj/holiday-cms',
                        parentId: 4
                    },
                    {
                        id: 5,
                        priority: 2,
                        label: 'Enquiry',
                        link: '/taj/holiday-enquiry',
                        parentId: 4
                    },
                   
                   
                ]
            },

            {
                id: 4,
                label: 'Hotel',
                icon: 'uil-bed',
                priority: 2,
                subItems: [

                    {
                        id: 5,
                        priority: 1,
                        label: 'CMS',
                        link: '/taj/hotel-cms',
                        parentId: 4
                    },
                    {
                        id: 5,
                        priority: 2,
                        label: 'Enquiry',
                        link: '/taj/hotel-enquiry',
                        parentId: 4
                    },
                   
                   
                ]
            },

            {
                id: 4,
                label: 'Flight',
                icon: 'uil-plane',
                priority: 2,
                subItems: [

                    {
                        id: 5,
                        priority: 1,
                        label: 'CMS',
                        link: '/taj/flight-cms',
                        parentId: 4
                    },
                   
                ]
            },

            {
                id: 5,
                priority: 1,
                label: 'News',
                icon: 'uil-newspaper',
                link: '/taj/travel-news',

            },
            

        ]
    },

   

];

